<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/tags">Ticket Tags</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a :href="`/tags/new`">New Tag</a>
    </div>
    <div class="row issue-wrapper">
      <div class="col-md-6 col-lg-4 p-4 issue-col bg-white">
        <h6 class="pt-2 text-dark text-header">Tag Name</h6>
        <input class="form-control" placeholder="Ticket tag name" v-model="tag.name"/>
        <br>
        <h6 class="pt-2 text-dark text-header">Tag Code</h6>
        <input class="form-control" placeholder="Ticket tag code" v-model="tag.code"/>
        <br>
        <h6 class="pt-2 text-dark text-header">Tag Type</h6>
        <ModelSelect
          v-model="tag.type"
          placeholder="Search tag type"
          :options="options.type"
          :isDisabled="this.user.role === 'Manager'"
        >
        </ModelSelect>
        <br>
        <h6 class="pt-2 text-dark text-header">
          Is Locked &nbsp;&nbsp;
          <i class="fas fa-info-circle cursor-pointer" v-tooltip="`Ticket tags that are classified as locked can never be changed or removed once tagged to a ticket.`"></i>
        </h6>
        <ModelSelect
          v-model="tag.isLocked"
          placeholder="Choose if tag is locked or not"
          :options="options.locked"
          :isDisabled="true"
        >
        </ModelSelect>
        <br>
        <h6 class="pt-2 text-dark text-header">Description</h6>
        <textarea class="form-control" rows="5" placeholder="Write something about this tag" v-model="tag.description"></textarea>
        <br>
        <div class="col-12">
          <br>
          <div class="w-100 text-right">
            <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline" v-on:click="createTag">
              <i class="btn p-0 text-white fas fa-save"></i>&nbsp; Create tag
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 border-left p-4 issue-col bg-white element-disabled">
        <h6 class="pt-2 text-dark text-header">Access management</h6>
        <ModelSelect
          :isDisabled="true"
          v-model="formControl.client"
          placeholder="Search client"
          :options="options.clients"
        >
        </ModelSelect>
        <div class="w-100 mt-4 text-right">
          <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline element-disabled">
            <i class="fas fa-plus"></i>&nbsp; Add client
          </div>
        </div>
        <br>
        <div class="mb-4 pb-3 border-bottom"></div>
        <div v-if="formControl.clients && formControl.clients.length > 0">
          <div class="row mt-2" v-for="client in formControl.clients" v-bind:key="client.key">
            <small class="col text-left">{{client.name}}</small>
            <div class="col-2">
              <div class="btn btn-sm btn-warning">
                <i class="fas fa-minus"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 text-center text-muted my-5 py-5" v-else>
          Ticket tag is available for all clients
        </div>
      </div>
      <div class="col-lg-5 border-left p-4 issue-col bg-white element-disabled">
        <div class="p-5 m-5 text-center text-muted" v-if="tag.type !== `issueClassification`">
          Custom forms are only for Issue Classification / Templates
        </div>
        <div v-else>
          <h6 class="pt-2 text-dark text-header">Custom forms</h6>
          <div class="row p-0 m-0">
            <div class="col-6 p-1">
              <input disabled type="text" class="form-control" placeholder="Field name" v-model="formControl.form.name">
            </div>
            <div class="col-6 p-1">
              <input disabled type="text" class="form-control" placeholder="Field key" v-model="formControl.form.key">
            </div>
            <div class="col-6 p-1">
              <ModelSelect
                :isDisabled="true"
                v-model="formControl.form.type"
                placeholder="Field type"
                :options="options.form.types"
              >
              </ModelSelect>
            </div>
            <div class="col-6 p-1">
              <ModelSelect
                :isDisabled="true"
                v-model="formControl.form.required"
                placeholder="Required field"
                :options="options.form.required"
              >
              </ModelSelect>
            </div>
            <div class="col-12 p-1">
              <textarea
                disabled
                class="form-control"
                rows="2"
                v-model="formControl.form.options"
                v-if="formControl.form.type === 'select'"
                placeholder="Select options (comma separated - Ex. Option 1, Option 2, etc.)"
              >
              </textarea>
            </div>
          </div>
          <div class="w-100 mt-4 text-right">
            <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline element-disabled">
              <i class="fas fa-plus"></i>&nbsp; Add Form Field
            </div>
          </div>
          <br>
          <div class="mb-4 pb-3 border-bottom"></div>
          <h6 class="pt-2 text-dark text-header">Sample HTML:</h6>
          <div class="px-3" v-if="formControl.forms && formControl.forms.length > 0">
            <div class="row mt-4" v-for="form in formControl.forms" v-bind:key="form.key">
              <div class="col text-left" v-tooltip="`Field key is ${form.key} and is ${form.required ? '' : 'not '} required`">
                <h6 class="text-dark text-header">{{form.name}}</h6>
                <input disabled type="text" class="form-control" :placeholder="form.name" v-if="form.type === 'text'">
                <input disabled type="number" class="form-control" :placeholder="form.name" v-if="form.type === 'number'">
                <textarea disabled rows="3" class="form-control" :placeholder="form.name" v-if="form.type === 'textarea'"></textarea>
                <ModelSelect
                  :isDisabled="true"
                  v-model="formControl.sampleSelect"
                  :placeholder="form.name"
                  :options="$buildOptions(form.options)"
                  v-if="form.type === 'select'"
                >
                </ModelSelect>
              </div>
              <div class="col-1">
                <h6 class="text-dark text-header">&nbsp;</h6>
                <div class="btn btn-sm btn-warning element-disabled">
                  <i class="fas fa-minus"></i>
                </div>
              </div>
            </div>
            <br>
          </div>
          <div class="w-100 text-center text-muted my-5 py-5" v-else>
            No additional fields
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ModelSelect } from 'vue-search-select'

  export default {
    components: {
      ModelSelect
    },
    props: {
      code: String
    },
    data() {
      return {
        user: this.$userData.user,
        tag: {
          name: '',
          code: '',
          type: 'label',
          isLocked: false,
          description: '',
          isInternal: false
        },
        tags: [],
        options: {
          clients: [],
          type: this.$tagTypes,
          locked: [
            { key: 'true', text: 'Locked', value: true },
            { key: 'false', text: 'Unlocked', value: false }
          ],
          form: {
            required: [
              { key: 'true', text: 'Required', value: true },
              { key: 'false', text: 'Optional', value: false }
            ],
            types: [
              { key: 'text', text: 'Text', value: 'text' },
              { key: 'number', text: 'Number', value: 'number' },
              { key: 'textarea', text: 'Text Area', value: 'textarea' },
              { key: 'select', text: 'Select', value: 'select' }
            ]
          }
        },
        formControl: {
          client: {},
          clients: [],
          form: {
            name: '',
            key: '',
            type: '',
            required: false,
            options: ''
          },
          forms: [],
          sampleSelect: ''
        }
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      async getTags(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/tags?isInternal=${this.$internalMode}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.tags = response.data.tags
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      createTag: function (){
        let errors = []
        if(this.tag.name.trim() === '')
          errors.push('Ticket tag name cannot be empty')
        if(this.tag.code.trim() === '')
          errors.push('Ticket tag code cannot be empty')
        if(this.tag.code.toLowerCase().trim() === 'new')
          errors.push("Invalid code. The word 'new' is reserved")
        if(this.tags.filter(r => r._id != this.tag._id).map(r => r.code).includes(this.tag.code))
          errors.push('Ticket tag code already exists, try another one')
        if (errors.length > 0){
          errors.reverse().forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'Create Failed',
              type: 'warn',
              text: error
            })
          });
          return false;
        }
        else {
          this.submitTag({
            name: this.tag.name,
            code: this.tag.code,
            type: this.tag.type,
            isLocked: this.tag.isLocked,
            description: this.tag.description,
            isInternal: this.$internalMode
          })
        }
      },
      async submitTag(tag){
        this.loader(true)
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/tags`,
            {
              tag: tag
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.tag && response.data.tag._id) {
            this.$alertify({
              group: 'notification',
              title: 'Create successful',
              type: 'success',
              text: 'New ticket tag has been saved.'
            })
            this.$router.push(`/tags/${response.data.tag.code}`)
          }
        } catch (error) {
          this.loader(false)
          console.log(error)
          this.$alertify({
            group: 'notification',
            title: 'Create Failed',
            type: 'warn',
            text: 'Something went wrong, please try again.'
          })
        }
      },
      validateUser: async function(){
       if(!['Admin', 'Manager'].includes(this.user.role)){
         this.$alertify({
           group: 'notification',
           title: `Access denied`,
           type: 'warning',
           text: `You don't have access to this module.`
         })
         await this.$sleep(500);
         window.history.back()
       }
     }
    },
    mounted: function() {
      document.title = `Ticket Tags | iRipple Helpdesk`;
      this.validateUser()
      this.getTags()
    }
  }
</script>
